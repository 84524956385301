<template>
  <section>
    <van-nav-bar title="采购新商品" left-arrow @click-left="backSpace" />
    <van-form @submit="submit">
      <van-field
        v-model="form.url"
        name="商品链接"
        label="商品链接"
        placeholder="商品链接"
      />
      <van-field name="商品规格截图" label="商品规格截图">
        <template #input>
          <van-uploader v-model="fileList" multiple />
        </template>
      </van-field>
      <van-field
        v-model="form.price"
        name="商品原价"
        label="商品原价"
        placeholder="商品原价"
      />
      <van-field
        v-model="form.pay"
        disabled
        name="实付金额"
        label="实付金额"
        placeholder="实付金额"
      />
      <van-field
        v-model="form.receiver_name"
        name="收货人"
        label="收货人"
        placeholder="收货人"
      />
      <van-field
        v-model="form.receiver_phone"
        name="收货人电话"
        label="收货人电话"
        placeholder="收货人电话"
      />
      <van-field
        readonly
        clickable
        name="area"
        :value="form.area"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-field
        v-model="form.receiver_address"
        name="收货人地址"
        label="收货人地址"
        placeholder="收货人地址"
      />
      <van-field
        v-model="form.receiver_email"
        name="收货人邮箱"
        label="收货人邮箱"
        placeholder="收货人邮箱"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="getarea"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field name="radio" label="类型">
        <template #input>
          <van-radio-group v-model="form.bill_type" direction="horizontal">
            <van-radio name="1">代购元器件</van-radio>
            <van-radio name="2">办公用品</van-radio>
            <van-radio name="3">信息服务费</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="form.code"
        name="发票抬头"
        label="发票抬头"
        placeholder="发票抬头"
      />
      <van-field
        v-model="form.num"
        name="税号"
        label="税号"
        placeholder="税号"
      />
      <div class="module">
        <div class="module_title fa sb">
          <span>自定义型号</span>
          <span class="f1 faj" v-if="total != ''">共计:{{ total }}元</span>
          <van-icon @click="add" class="module_icon" name="add-o" />
        </div>
        <div>
          <div
            v-for="(item, index) in form.sku"
            :key="index"
            class="module_item fa"
          >
            <input
              type="text"
              v-model="form.sku[index].title"
              placeholder="请输入型号"
            />
            <input
              type="text"
              v-model="form.sku[index].price"
              placeholder="请输入单价"
            />
            <input
              type="text"
              v-model="form.sku[index].num"
              placeholder="请输入数量"
            />
            <van-icon @click="cut(index)" class="module_icon" name="close" />
          </div>
        </div>
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </section>
</template>
<script>
import Vue from "vue";
import qs from "qs";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
import { addNewProduct, getMemberInfo } from "@/assets/api/api";
import { areaList } from "@vant/area-data";
import { Uploader } from "vant";
Vue.use(Uploader);
export default {
  data() {
    return {
      form: {
        url: "",
        thumb: [],
        price: "",
        pay: "",
        receiver_name: "",
        receiver_phone: "",
        receiver_address: "",
        receiver_email:"",
        area: "",
        bill_type: "",
        sku: [
          {
            price: "",
            num: "",
            title: "",
          },
        ],
        code: "",
        num: "",
        type: "1",
      },
      total: "",
      fileList: [],
      showArea: false,
      areaList: areaList,
    };
  },
  computed: {},
  watch: {
    form: {
      handler() {
        this.getTotal();
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      let data = [];
      for (let i = 0; i < this.fileList.length; i++) {
        data.push(this.fileList[i].content);
      }
      this.form.thumb = data;
      if (this.form.url == "") {
        Toast("商品链接不能为空");
      } else if (this.form.thumb.length == 0) {
        Toast("商品规格截图不能为空");
      } else if (this.form.price == "") {
        Toast("商品原价不能为空");
      } else if (this.form.receiver_name == "") {
        Toast("收货人不能为空");
      } else if (this.form.receiver_phone == "") {
        Toast("收货人电话不能为空");
      } else if (this.form.area == "") {
        Toast("请选择地区");
      } else if (this.form.receiver_address == "") {
        Toast("收货人地址不能为空");
      } else if (this.form.receiver_email == "") {
        Toast("收货人邮箱不能为空");
      } else if (this.form.bill_type == "") {
        Toast("请选择类型");
      } else if (this.form.code == "") {
        Toast("发票抬头不能为空");
      } else if (this.form.num == "") {
        Toast("税号不能为空");
      } else if (this.form.sku.length == 0) {
        Toast("自定义型号不能为空");
      } else {
        let priceList = [];
        for (let i = 0; i < this.form.sku.length; i++) {
          priceList.push(this.form.sku[i].price * this.form.sku[i].num);
        }
        if (this.sum(priceList) != parseInt(this.form.pay)) {
          Toast("请检查采购商品的价格");
          return;
        } else {
          this.addNewProduct();
        }
      }
    },
    sum(arr) {
      var s = 0;
      for (var i = arr.length - 1; i >= 0; i--) {
        s += arr[i];
      }
      return s;
    },
    getTotal() {
      let priceList = [];
      for (let i = 0; i < this.form.sku.length; i++) {
        priceList.push(this.form.sku[i].price * this.form.sku[i].num);
      }
      this.total = this.sum(priceList);
      if (this.form.price == "") {
        this.form.pay = "";
      } else {
        this.form.pay = Math.ceil(this.form.price / 0.94);
      }
    },
    add() {
      let item = {
        price: "",
        num: "",
        title: "",
      };
      this.form.sku.push(item);
      this.$forceUpdate();
    },
    cut(index) {
      this.form.sku.splice(index, 1);
      this.$forceUpdate();
    },
    getarea(values) {
      this.form.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    addNewProduct() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      addNewProduct(qs.stringify(this.form)).then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          Toast("提交成功");
          setTimeout(() => {
            this.topay(res.data.data.id, res.data.data.pay);
          }, 1000);
        } else {
          Toast(res.data.message);
        }
      });
    },
    topay(id, pay) {
      this.$router.push("payoff?id=" + id + "&price=" + pay);
    },
    getMemberInfo() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      getMemberInfo().then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          this.form.receiver_name = res.data.data.realname;
          this.form.receiver_phone = res.data.data.phone;
          this.form.code = res.data.data.code;
          this.form.num = res.data.data.num;
          this.form.area = res.data.data.area;
          this.form.receiver_address = res.data.data.address;
          this.form.receiver_email = res.data.data.receiver_email;
        } else {
          Toast(res.data.message);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getMemberInfo();
  },
};
</script>
<style scoped>
.module {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
.module_icon {
  font-size: 18px;
}
.module_item {
  margin-top: 10px;
}
.module_item input {
  border: none;
  padding: 0;
  min-width: 50px;
  background: none;
  font-size: 14px;
}
.van-radio {
  margin: 5px;
}
</style>
